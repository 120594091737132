import './PurchaseReference.css'

export default function PurchaseReference({ itemId, purchaseToken }) {
  return (
    <div class="pr-details">
      <h3>{itemId}</h3>
      <code>{purchaseToken}</code>
    </div>
  )
}
