import './Button.css';

export default function Button( { label, bg = 'bg-dark-blue', disabled = false, onClick = (event) => {} }) {
  return (
    <button 
        className={"button " + bg}
        disabled={disabled}
        onClick={onClick}>
      {label}
    </button>
  )
}
