import Card from "./Card";

export default function TroubleshootingCard() {
  return (
    <Card title="Troubleshooting" bg="bg-light-green">
      <p>You must be using Chrome 88.0.4323.0+. It should be set as your default.</p>
      <p>You must be viewing this website from inside a TWA. Follow this link to install <a href="https://play.google.com/apps/testing/dev.conn.drink">the test app</a>. If you want to test on production SKUs without spending actual money, send your Google email address to <a href="mailto:peconn@chromium.org">peconn@chromium.org</a>.</p>
      <p>Please ensure you have the following enabled in your <span className="code">chrome://flags</span>.</p>
      <ul className="code tl">
        <li>#enable-experimental-web-platform-features</li>
        <li>#enable-web-payments-experimental-features</li>
        <li>#enable-debug-for-store-billing</li>
      </ul>
    </Card>
  )
}
