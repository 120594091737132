import './Card.css'

export default function Card({ bg, title, children }) {
  return (
    <div className={"center measure-wide br2 mh2 mt2 ph4 pv2 avenir " + bg}>
      <h1>{title}</h1>
      {children}
    </div>
  )
}
