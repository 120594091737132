import './SingleSkuDetails.css';

export default function SingleSkuDetails({ title, itemId, description, price }) {

  return (
    <div class="sku-details">
      <div class="sku-title">
        <h3>{title}</h3>
        <span className="price">{price}</span>
      </div>
      <code>{itemId}</code>
      <p>{description}</p>
    </div>
  )
}
