import { useState, useEffect } from 'react';

import Card from "./Card";
import PurchaseReference from "./PurchaseReference";
import Button from './Button';

const PAYMENT_METHOD = 'https://play.google.com/billing';

export default function PurchaseReferencesCard({ updateTrigger, triggerUpdate, appendLog }) {
  const [ purchases, setPurchases ] = useState([]);

  useEffect(() => {
    let cancelled = false;
    const run = async () => {
      appendLog("Getting purchases.");
      try {
        const service = await window.getDigitalGoodsService(PAYMENT_METHOD);
        const purchases = await service.listPurchases();
        console.log(purchases);
        appendLog("Purchases:");
        appendLog(JSON.stringify(purchases, null, '  '));

        if (!cancelled) {
          setPurchases(purchases);
        }
      } catch (error) {
        console.log(error);
      }
    }

    run();
    return () => {
      cancelled = true;
    }
  }, [updateTrigger]);

  const consume = async (purchaseToken) => {
    appendLog("Consuming " + purchaseToken);
    try {
      const service = await window.getDigitalGoodsService(PAYMENT_METHOD);
      console.log(service.consume);
      await service.consume(purchaseToken);

      triggerUpdate();
    } catch (error) {
      appendLog("Could not consume, maybe Play is rate limiting us.");
      appendLog(error.message);
    }
  };

  return (
    <Card title="Purchases" bg="bg-light-blue">
      {purchases && purchases.map(purchase => (
        <>
          <PurchaseReference
            itemId={purchase.itemId}
            purchaseToken={purchase.purchaseToken}
          />
          <Button
            label="Consume"
            onClick={() => consume(purchase.purchaseToken)}
          />
        </>
      ))}
    </Card>
  )
}
