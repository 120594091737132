import { useState } from 'react';

import './App.css';
import Card from './components/Card';
import TroubleshootingCard from './components/TroubleshootingCard';
import SkuDetailsCard from './components/SkuDetailsCard';
import SingleSkuDetails from './components/SingleSkuDetails';
import PurchaseReference from './components/PurchaseReference';
import PurchaseReferencesCard from './components/PurchaseReferencesCard';

function App() {
  const [ updateTrigger, setUpdateTrigger ] = useState(0);
  const [ log, setLog ] = useState();

  const triggerUpdate = () => {
    setTimeout(() => setUpdateTrigger(v => v + 1), 200);
  }

  const appendLog = (message) => {
    setLog(log => {
      if (log) {
        return log + "\n" + message;
      } else {
        return message;
      }
    })
  }

  return (
    <div className="App pa2">

      {!window.getDigitalGoodsService && (
        <>
          <Card title="No Service" bg="bg-light-red">
            <p>Sorry, there was no <b>getDigitalGoodsService</b> method on <b>window</b>.</p>
          </Card>

          <Card title="Layout Examples" bg="bg-light-purple">
            <SingleSkuDetails
              title="Example Title"
              itemId="example.id"
              description="Some long winding descriptive text."
              price="£3.95"
            />
            <PurchaseReference
              itemId="example.id"
              purchaseToken="abc.def.ghi"
            />
          </Card>

          <TroubleshootingCard />
        </>
      )}

      {window.getDigitalGoodsService && (
        <>
          <SkuDetailsCard
            updateTrigger={updateTrigger}
            triggerUpdate={triggerUpdate}
            appendLog={appendLog} />
          <PurchaseReferencesCard
            updateTrigger={updateTrigger}
            triggerUpdate={triggerUpdate}
            appendLog={appendLog} />
        </>
      )}

      <Card title="Log" bg="bg-light-yellow">
        <pre>{log}</pre>
      </Card>
      
    </div>
  );
}

export default App;
