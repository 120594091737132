import { useState, useEffect } from 'react';
import Card from './Card';
import SingleSkuDetails from './SingleSkuDetails';
import { triggerPaymentFlow } from '../logic/payment';
import Button from './Button';

const PAYMENT_METHOD = 'https://play.google.com/billing';
const SKUS = [
  'tea',
  'coffee_yearly',
];
const TEST_SKUS = [
  'android.test.purchased',
  'android.test.canceled',
]

export default function SkuDetailsCard({ updateTrigger, triggerUpdate, appendLog }) {
  const [ skuDetails, setSkuDetails ] = useState([])
  const [ includeTestSkus, setIncludeTestSkus ] = useState(false);

  useEffect(() => {
    let cancelled = false;
    const run = async () => {
      appendLog("Getting item details.");
      try {
        const service = await window.getDigitalGoodsService(PAYMENT_METHOD);
        const skus = includeTestSkus ? [...SKUS, ...TEST_SKUS] : SKUS;
        const details = await service.getDetails(skus);
        console.log(details);
        appendLog("Item Details:");
        appendLog(JSON.stringify(details, null, '  '));

        if (!cancelled) {
          setSkuDetails(details);
        }
      } catch (error) {
        console.log(error);
      }
    }

    run();
    return () => {
      cancelled = true;
    }
  }, [updateTrigger, includeTestSkus]);

  return (
    <Card title="SKU Details" bg="bg-light-green">
      <label>
        <span>Include testing SKUs:</span>
        <input
          type="checkbox"
          checked={includeTestSkus}
          onChange={(e) => setIncludeTestSkus(e.target.checked)}
        />
      </label>
      <br />

      {skuDetails && skuDetails.map(details => {
        const formatter = new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: details.price.currency
        });
        const price = formatter.format(parseFloat(details.price.value));

        return (
          <>
            <SingleSkuDetails
              title={details.title}
              description={details.description}
              itemId={details.itemId}
              price={price}
            />
            <Button
              label="Buy"
              onClick={() => triggerPaymentFlow(details.itemId, triggerUpdate, appendLog)}
              bg = 'bg-dark-green'
            />
          </>
        )
      })}
    </Card>
  )
}
