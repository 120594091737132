const PAYMENT_METHOD = 'https://play.google.com/billing';

export const triggerPaymentFlow = (sku, onComplete, log=(m) => console.log(m)) => {
  if (!window.PaymentRequest) {
    log("No PaymentRequest object.");
    return;
  }

  const supportedInstruments = [{
    supportedMethods: PAYMENT_METHOD,
    data: { sku: sku }
  }]

  const details = {
    total: {
      label: 'Placeholder',
      amount: { currency: 'GBP', value: '0' }
    },
  };

  const request = new PaymentRequest(supportedInstruments, details);

  if (request.canMakePayment) {
    request
      .canMakePayment()
      .then(function (result) {
        log(result ? 'Can make payment' : 'Cannot make payment');
      })
      .catch(function (e) {
        log(e.message);
      });
  }

  if (request.hasEnrolledInstrument) {
    request
      .hasEnrolledInstrument()
      .then(function (result) {
        if (result) {
          log('Has enrolled instrument');
        } else {
          log('No enrolled instrument');
        }

        // Call show even if we don't have any enrolled instruments.
        request
          .show()
          .then(handlePaymentResponse)
          .catch(function (e) {
            // log(JSON.stringify(e, undefined, 2));
            log(e);
            log("Maybe you've already purchased the item.");
          });
      })
      .catch(function (e) {
        log(e.message);

        // Also call show if hasEnrolledInstrument throws.
        request
          .show()
          .then(handlePaymentResponse)
          .catch(function (e) {
            log(JSON.stringify(e, undefined, 2));
            log(e);
          });
      })
  }

  function handlePaymentResponse(response) {
    window.setTimeout(function () {
      response
        .complete('success')
        .then(function () {
          log(`Payment done: ${JSON.stringify(response, undefined, 2)}`);
          onComplete();
        })
        .catch(function (e) {
          log(e.message);
          log(JSON.stringify(response, undefined, 2));
        })
      // request = buildPaymentRequest();
    }, 500);
  }
}